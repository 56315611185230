import {parseDate} from "@/composables/date_utils";

let getValue = (val) => val || 0;
let findTimeFieldIds = (name, fields) => {
  let time_fields_ids = [];
  fields.forEach((field) => {
    if (field.type === "NUMBER") {
      time_fields_ids.push(field.field_id);
    }
  });
  return time_fields_ids;
};

let tallyHours = (time_fields_ids, entry_fields, commit) => {
  time_fields_ids.forEach((time_field_id) => {
    let field = entry_fields.find((field) => {
      return field.fieldwork_log_field_id === time_field_id ? field : null;
    });
    if (field) {
      commit("incrementHours", {
        hours: parseFloat(getValue(field.value)),
      });
    }
  });
};

let tallyCLEHours = (cle_hours, time_fields_ids, entry_fields, commit) => {
  let total = 0;
  time_fields_ids.forEach((time_field_id) => {
    let field = entry_fields.find((field) => {
      return field.fieldwork_log_field_id === time_field_id ? field : null;
    });
    total += parseFloat(getValue(field.value));
  });
  if (total) {
    let to_add = 0;
    if (cle_hours >= 2) {
      commit("set_cle_hours", total);
    } else {
      let rem = 2 - cle_hours;
      if (total < rem) {
        to_add = total;
      } else {
        to_add = rem;
      }
      commit("set_cle_hours", total);
    }
    if (to_add) {
      commit("incrementHours", {
        hours: to_add,
      });
    }
  }
};

let tallyOrientationHours = (
  orientation_hours,
  time_fields_ids,
  entry_fields,
  commit
) => {
  let total = 0;
  time_fields_ids.forEach((time_field_id) => {
    let field = entry_fields.find((field) => {
      return field.fieldwork_log_field_id === time_field_id ? field : null;
    });
    total += parseFloat(getValue(field.value));
  });
  if (total) {
    let to_add = 0;
    if (orientation_hours >= 2) {
      commit("set_orientation_hours", total);
    } else {
      let rem = 2 - orientation_hours;
      if (total < rem) {
        to_add = total;
      } else {
        to_add = rem;
      }
      commit("set_orientation_hours", total);
    }
    if (to_add) {
      commit("incrementHours", {
        hours: to_add,
      });
    }
  }
};

let tallyNetworkingHours = (
  networking_hours,
  time_fields_ids,
  entry_fields,
  level,
  commit
) => {
  let level_total = 0;
  if (level === "1L" || level === "LLM") {
    level_total = 3;
  } else {
    level_total = 5;
  }
  let total = 0;
  time_fields_ids.forEach((time_field_id) => {
    let field = entry_fields.find((field) => {
      return field.fieldwork_log_field_id === time_field_id ? field : null;
    });
    total += parseFloat(getValue(field?.value));
  });
  if (total) {
    let to_add = 0;
    if (networking_hours >= level_total) {
      // Max should be 3 (1L, LLM) or 5 (2L, 3L) based on year
      commit("set_networking_hours", total);
    } else {
      let rem = level_total - networking_hours;
      if (total < rem) {
        to_add = total;
      } else {
        to_add = rem;
      }
      commit("set_networking_hours", total);
    }
    if (to_add) {
      commit("incrementHours", {
        hours: to_add,
      });
    }
  }
};

const state = {
  fieldwork_timestamp: "",
  form_loading: false,
  entries: [],
  distinct_experiences: [],
  cle_hours: 0,
  networking_hours: 0,
  orientation_hours: 0,
  progress_items: [
    {
      name: "Plan (PPDP)",
      plan_complete: false,
      meeting_approved: false,
      progress: {
        complete: 0,
        total_complete: 0,
        total: 1,
      },
    },
    {
      name: "Distinct Experiences",
      progress: {
        complete: 0,
        total_complete: 0,
        total: 4,
      },
    },
    {
      name: "Debriefings",
      progress: {
        complete: 0,
        total_complete: 0,
        total: 2,
      },
    },
    {
      name: "Fieldwork Hours",
      progress: {
        complete: 0,
        total_complete: 0,
        total: 18,
      },
    },
  ], // this object stores progress for each section (complete/total)
  admin_fieldwork_records_filters: {
    year: "",
    first_name: "",
    last_name: "",
    status: "TODO",
    type: "",
  },
};

const getters = {
  get_admin_fieldwork_records_filters: (state) =>
    state.admin_fieldwork_records_filters,
  form_types: (state) => {
    return state.form_types;
  },
  form_type: (state) => (form_type) => {
    return state.form_types[form_type];
  },
  student_entries: (state) => {
    return state.entries.sort((a, b) => {
      return parseDate(b.updated, true) - parseDate(a.updated, true);
    });
  },
  form_loading: (state) => {
    return state.form_loading;
  },
  experience_entries: (state) => {
    let experiences = [];
    let experience_id = state.form_types.experience.id;
    state.entries.forEach((entry) => {
      if (entry.fieldwork_type_id === experience_id) {
        experiences.push(entry);
      }
    });
    return experiences;
  },
  form_type_description: (state, getters) => (form_type) => {
    return getters.lookup_fieldwork_types.find((fieldwork_type) => {
      return (
        fieldwork_type.fieldwork_type_name
          .replace(/[-\s]/g, "_")
          .toLowerCase() === form_type.replace(/[-\s]/g, "_")
      );
    }).description;
  },
  progress_items: (state) => {
    return state.progress_items;
  },
};

const mutations = {
  set_admin_fieldwork_records_filters: (state, filters) => {
    Object.keys(filters).forEach((key) => {
      state.admin_fieldwork_records_filters[key] = filters[key];
    });
  },
  set_entries(state, entries) {
    state.entries = [];
    state.entries = [...entries];
    // console.log(state.entries);
  },
  set_progress_item_totals(state, level) {
    switch (level.value) {
      case "0": // LLM
        state.progress_items[0].progress.total = 1;
        state.progress_items[1].progress.total = 4;
        state.progress_items[2].progress.total = 2;
        state.progress_items[3].progress.total = 18;
        break;
      case "1": // 1L
        state.progress_items[0].progress.total = 1;
        state.progress_items[1].progress.total = 4;
        state.progress_items[2].progress.total = 2;
        state.progress_items[3].progress.total = 18;
        break;
      case "2": // 2L
        state.progress_items[0].progress.total = 1;
        state.progress_items[1].progress.total = 5;
        state.progress_items[2].progress.total = 2;
        state.progress_items[3].progress.total = 30;
        break;
      case "3": // 3L
        state.progress_items[0].progress.total = 1;
        state.progress_items[1].progress.total = 5;
        state.progress_items[2].progress.total = 2;
        state.progress_items[3].progress.total = 30;
        break;
    }
  },
  set_cle_hours(state, total) {
    state.cle_hours += total;
  },
  set_networking_hours(state, total) {
    state.networking_hours += total;
  },
  set_orientation_hours(state, total) {
    state.orientation_hours += total;
  },
  incrementDebriefComplete(state) {
    if (
      state.progress_items[2].progress.complete <
      state.progress_items[2].progress.total
    ) {
      state.progress_items[2].progress.complete++;
    }
  },
  incrementHours(state, payload) {
    if (
      state.progress_items[3].progress.complete <
      state.progress_items[3].progress.total
    ) {
      state.progress_items[3].progress.complete += payload.hours;
      state.progress_items[3].progress.total <
      state.progress_items[3].progress.complete
        ? (state.progress_items[3].progress.complete =
            state.progress_items[3].progress.total)
        : 0;
      state.progress_items[3].progress.total_complete = state.progress_items[3].progress.complete
    }else {
      state.progress_items[3].progress.total_complete+= payload.hours;

    }
  },
  incrementPlan(state, to_update) {
    if (to_update === "meeting") {
      state.progress_items[0].meeting_approved = true;
    }
    if (to_update === "plan") {
      state.progress_items[0].plan_complete = true;
    }
    if (
      state.progress_items[0].meeting_approved &&
      state.progress_items[0].plan_complete &&
      state.progress_items[0].progress.complete <
        state.progress_items[0].progress.total
    ) {
      state.progress_items[0].progress.complete++;
    }
  },
  incrementDistinctExperiences(state, payload) {
    if (
      state.progress_items[1].progress.complete <
      state.progress_items[1].progress.total
    ) {
      // find experience field id in payload.type_fields
      let experience_field_id = payload.type_fields.find(
        (field) => field.name === "Experience Type"
      ).field_id;
      // find experience field in payload.entry_fields
      let experience_field = payload.entry_fields.find(
        (field) => field.fieldwork_log_field_id === experience_field_id
      );
      // check field value, if value exists in state.distinct_experiences, ignore
      if (
        experience_field &&
        !state.distinct_experiences.find(
          (experience) => experience === experience_field.value
        )
      ) {
        if (experience_field.value !== null) {
          state.distinct_experiences.push(experience_field.value);
          state.progress_items[1].progress.complete++;
          // if total less than progress, set progress to total
          state.progress_items[1].progress.total <
          state.progress_items[1].progress.complete
            ? (state.progress_items[1].progress.complete =
                state.progress_items[1].progress.total)
            : 0;
        }
        state.progress_items[1].progress.total_complete = state.progress_items[1].progress.complete;
      }
    }else {
      state.progress_items[1].progress.total_complete++
    }
  },
};

const actions = {
  postFieldworkEntry: async ({ dispatch, state }, data) => {
    state.form_loading = !state.form_loading;
    await dispatch("fetchResource", {
      method: "POST",
      resource: "student/fieldworks",
      body: {
        ...data.data,
        fieldwork_type_id: data.fieldwork_type_id,
        student_at_level_id: data.student_at_level_id,
      },
    })
      .then(() => {
        dispatch("getFieldworkEntries");
      })
      .then(() => {
        state.form_loading = !state.form_loading;
      })
      .catch((error) => {
        console.log("error (postFieldworkEntry):", error);
      });
  },
  putFieldworkEntry: async ({ dispatch, state }, data) => {
    state.form_loading = !state.form_loading;
    await dispatch("fetchResource", {
      method: "PUT",
      resource: `student/fieldworks/${data.data.id}`,
      // params: { student_at_level_id: `${data.student_at_level_id}` },
      body: { ...data.data },
    })
      .then(() => {
        dispatch("getFieldworkEntries", {
          student_at_level_id: `${data.student_at_level_id}`,
        });
      })
      .then(() => {
        state.form_loading = !state.form_loading;
      })
      .catch((error) => {
        console.log("error: (putFieldworkEntry):", error);
      });
  },
  adminPutFieldworkEntry: async ({ dispatch, state }, data) => {
    state.form_loading = !state.form_loading;
    await dispatch("fetchResource", {
      method: "PUT",
      resource: `admin/fieldworks/${data.data.id}`,
      body: { ...data.data },
    })
      .then(() => {
        state.form_loading = !state.form_loading;
      })
      .catch((error) => {
        console.log("error (adminPutFieldworkEntry):", error);
      });
  },
  getFieldworkEntries: async ({ dispatch, commit, state, rootState }) => {
    state.form_loading = !state.form_loading;

    await dispatch("fetchResource", {
      method: "GET",
      resource: "student/fieldworks",
      params: {
        student_at_level: rootState.level_store.acad_level.student_at_level_id,
      },
    })
      .then((response) => {
        commit("set_entries", response);
      })
      .then(() => {
        dispatch("calculateHours");
      })
      .then(() =>
        commit("set_milestone_status", {
          form_data: rootState.fieldwork_form_store,
          plan_data: rootState.plan,
          lookup_data: rootState.lookup_data,
        })
      )
      .then(() => {
        state.form_loading = !state.form_loading;
      })
      .catch((error) => {
        console.log("error (fetchResource):", error);
      });
  },
  calculateHours: async ({ commit, state, rootState }) => {
    let time_fields_ids = [];
    // TODO: reset progress items complete to 0
    state.progress_items.forEach((item) => {
      item.progress.complete = 0;
    });
    state.progress_items[0].plan_complete = false;
    state.progress_items[0].meeting_approved = false;
    state.distinct_experiences = [];
    state.cle_hours = 0;
    state.networking_hours = 0;
    state.orientation_hours = 0;
    state.entries.forEach((entry) => {
      if (entry.event_status !== "APPROVED") {
        return;
      }
      // match fieldwork type id to fieldwork type id in lookup store
      let fieldwork_type = rootState.lookup_data.fieldwork_types.find(
        (fieldwork_type) => {
          return fieldwork_type.fieldwork_log_type_id ===
            entry.fieldwork_type_id
            ? fieldwork_type.fieldwork_type_name
            : null;
        }
      );
      let hours_bypass = false;
      switch (fieldwork_type.fieldwork_type_name) {
        case "Experience":
          // increment experiences count
          commit("incrementDistinctExperiences", {
            entry_fields: entry.fields,
            type_fields: fieldwork_type.fields,
          });
          time_fields_ids = findTimeFieldIds(
            "Experience",
            fieldwork_type.fields
          );
          entry.fields.forEach((f) => {
            if (
              f.value ===
              "Attend a continuing legal education (CLE) program without your mentor (2-hour maximum credit)"
            ) {
              hours_bypass = true;
              tallyCLEHours(
                state.cle_hours,
                time_fields_ids,
                entry.fields,
                commit
              );
            }
          });
          if (!hours_bypass) {
            tallyHours(time_fields_ids, entry.fields, commit);
          }
          break;
        case "Debrief":
          commit("incrementDebriefComplete");
          time_fields_ids = findTimeFieldIds("Debrief", fieldwork_type.fields);
          tallyHours(time_fields_ids, entry.fields, commit);
          break;
        case "Orientation":
          // only counts hours, no increments
          time_fields_ids = findTimeFieldIds(
            "Orientation",
            fieldwork_type.fields
          );
          tallyOrientationHours(
            state.orientation_hours,
            time_fields_ids,
            entry.fields,
            commit
          );
          break;
        case "Year-End Meeting":
          // only counts hours
          time_fields_ids = findTimeFieldIds(
            "Year-End Meeting",
            fieldwork_type.fields
          );
          tallyHours(time_fields_ids, entry.fields, commit);
          break;
        case "Networking":
          // only counts hours
          time_fields_ids = findTimeFieldIds(
            "Networking",
            fieldwork_type.fields
          );
          tallyNetworkingHours(
            state.networking_hours,
            time_fields_ids,
            entry.fields,
            rootState.level_store.acad_level.level,
            commit
          );
          break;
        case "PPDP Meeting":
          commit("incrementPlan", "meeting");
          time_fields_ids = findTimeFieldIds(
            "PPDP Meeting",
            fieldwork_type.fields
          );
          tallyHours(time_fields_ids, entry.fields, commit);
          break;
        case "Point of Contact":
          // only counts hours
          time_fields_ids = findTimeFieldIds(
            "Point of Contact",
            fieldwork_type.fields
          );
          tallyHours(time_fields_ids, entry.fields, commit);
          break;
      }
    });
    let plans_complete = true;
    for (const key in rootState.plan.plans) {
      if (rootState.plan.plans[key].status) {
        if (typeof rootState.plan.plans[key].status === "string") {
            plans_complete = rootState.plan.plans[key].status === "COMPLETE";
        } else {
          if (rootState.plan.plans[key].status[0] !== "COMPLETE") {
            plans_complete = false;
          }
        }
      }
    }
    if (plans_complete) {
      commit("incrementPlan", "plan");
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
